import styled, { createGlobalStyle, css } from 'styled-components';
import debugBg from '@assets/images/etiqueta_makeb_main_store.png';

import { DomaineCode } from '@assets/styles/typography';


export const WrapperGear = styled.div`
 ${({debug}) => !debug && css`display: none;`};
  position: absolute;
  top: 25px;
  left: 25px;
  padding: 10px;
  z-index: 5;
  cursor: pointer;
  svg {
    fill: #a2a2a2;
  }
  &:hover {
    svg {
      fill: #000;
      transition: all 0.5s;
      transform: scale(1.1)
    }
  }
`;

export const ContainerDebugFields = styled.div`
  overflow: auto;
  max-height: 400px;
  padding-right: 20px;
`

export const PrintPage = createGlobalStyle`

  @page {
    margin: 1.1mm;
    size: A4;
  }

  * {
    -webkit-text-size-adjust: none;
  }

  .print {
    display: none;
  }

  .noprint {
  }

  body {
    margin: 0;
    background: #eee;
    overflow: hidden;
  }

  .flex-wrapper {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  @media print {
     .noprint {
        display: none;
     }
     .flex-wrapper {
        display: flex!important;
     }
  }

  .ticket {
    width: 210mm;
    height: 250mm;
    position: relative;
    z-index: 4;
    transform: rotate(-180deg);
  }

  .ticket:before {
    display: none;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    z-index: 0;

    background-image: url('${debugBg}');

    background-size: cover;
  }

  .ticket: after {
    content: "V8";
    padding: 3%;
    display: none;
    justify-content: center;
    align-items: center;
    border: 5px dashed black;
    position: fixed;
    top: 1%;
    left: 40%;
    transform: translateX(-50%);
    font-size: 23pt;
  }
`;

export const PrintedLabelName = styled.div`
  ${DomaineCode};
  letter-spacing: 0;
  font-size: ${({size})=>size}pt;
  line-height: 1;
  color: #000000;
  display: flex;
  align-items: center;
  text-align: left;
  position: absolute;
  width:  ${({width})=>width}mm;
  height: ${({height})=>height}mm;
  left: ${({left})=>left}%;
  top: ${({top})=>top}%;
  right: unset;
  bottom: unset;
  transform: rotate(270deg) translate(15%, -30%);
   ${({isBorder})=> isBorder && css`
   border: 5px dashed black;
   `};
`;

export const PrintedCode = styled.div`
  ${DomaineCode};
  letter-spacing: 0;
  font-size: ${({size})=>size}pt;
  position: absolute;
  left: ${({left})=>left}%;
  top: ${({top})=>top}%;
  transform: rotate(180deg);
  width:  ${({width})=>width}mm;
  height: ${({height})=>height}mm;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${({isBorder})=> isBorder && css`
   border: 5px dashed black;
   `};

  span {
    transform: rotate(180deg);
    display: flex;
    position: absolute;
    right: 0;
  }
`;
