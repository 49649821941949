import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import { getErrorMessage } from '@modules/errors';
import Icon from '@components/atoms/Icon';

import {
  Viewport,
  InputWrapper,
  StyledInput,
  IconWrapper,
  Label,
  Error,
} from './style';

const Input = ({ placeholder, error, type, mask, value, onChange, maxLength, label, onFocus, triggerFocusOut }) => {
  const inputRef = useRef(null);

  const [passVisible, setPassVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
    
    if (onFocus)
      onFocus()
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    if (triggerFocusOut)
      triggerFocusOut()
  }, [value]);

  return (
    <Viewport>
      {label && (
        <Label>{label}</Label>
      )}
      
      <InputWrapper>
        <InputMask mask={mask} defaultValue={value} onBlur={handleInputBlur} onFocus={handleInputFocus} onInput={onChange}>
          {(inputProps) => <StyledInput {...inputProps} ref={inputRef} placeholder={placeholder} error={error} type={passVisible ? 'text' : type} maxLength={maxLength} />}
        </InputMask>
        {type === 'password' && (
          <IconWrapper onClick={() => setPassVisible(!passVisible)}>
            <Icon name={passVisible ? 'eye-off' : 'eye'} />
          </IconWrapper>
        )}
      </InputWrapper>

      <Error error={error}>{error && getErrorMessage(error)}</Error>
    </Viewport>
  );
};
Input.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
  mask: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  triggerFocusOut: PropTypes.func,
};
Input.defaultProps = {
  placeholder: undefined,
  label: undefined,
  error: undefined,
  type: 'text',
  mask: undefined,
  maxLength: undefined,
  value: '',
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  triggerFocusOut: PropTypes.func,
};
export default Input;
