import React from 'react';
import PropTypes from 'prop-types';

import Loader from '@components/atoms/Loader'
import { gotoFullscreen } from '@src/helpers/fullscreen';

import { StyledButton } from './style';

const Button = ({ children, htmlType = 'button', type, loading, disabled, onClick, reverseArrow, border, fullscreen }) => {
  const handleClick = () => {
    if (fullscreen) {
      gotoFullscreen();
    }

    if (onClick) {
      onClick();
    }
  }

  return (
    <StyledButton type={htmlType} colorSchema={type} loading={loading ? 1 : 0} disabled={disabled} onClick={handleClick} reverseArrow={reverseArrow} border={border}>
      <Loader size="small" colorSchema={type} />
      <span>{children}</span>
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  htmlType: PropTypes.oneOf(['button', 'submit', 'reset']),
  type: PropTypes.oneOf(['primary', 'secondary', 'dark', 'ghost']),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  reverseArrow: PropTypes.bool,
  border: PropTypes.bool,
  fullscreen: PropTypes.bool,
};

Button.defaultProps = {
  htmlType: 'button',
  type: 'primary',
  loading: false,
  disabled: false,
  onClick: undefined,
  reverseArrow: false,
  border: false,
  fullscreen: false
};

export default Button;
