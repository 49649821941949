import styled from 'styled-components';
import { Green100 } from '@assets/styles/colors';
import { TitleLarge } from '@assets/styles/typography';

export const Viewport = styled.header`
  width: 100vw;
  padding: 60px 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

export const Logo = styled.img`
  position: absolute;
  left: 40px;
`;

export const Title = styled.span`
  ${TitleLarge};
  display: flex;
  color: ${Green100}
`;

export const Logout = styled.div`
  display: flex;
  position: absolute;
  right: 40px;
  cursor: pointer;
  color: ${Green100};

  span {
    display: inline-flex;
    padding-right: 8px;
  }

  svg {
    
  }
`