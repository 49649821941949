export const Primary = '#2B2B2B';
export const Secondary = '#fff';
export const White = '#FFFFFF';
export const Gray500 = '#989898';
export const Gray100 = '#F0F0F0';
export const Error = '#FF4D4F';

export const PageBg = '#FEFFFD';
export const InputBorder = '#E2EED7';
export const Green100 = '#3A6006';
export const Green200 = '#728674';
export const Green300 = '#7B866A';
