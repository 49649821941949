import { createGlobalStyle } from 'styled-components';
import closeImage from '@assets/images/close.svg';

import { Paragraph } from './typography';
import { Primary, White, PageBg } from './colors';

const Base = createGlobalStyle`
  body {
    ${Paragraph};
    color: ${Primary};
    background: ${PageBg};
  }
  html,
  body {
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: ${PageBg};
  }

  .Toastify__toast-container--top-center {
    top: 0 !important;
    padding: 0 !important;
    width: 680px !important;
  }

  .Toastify__toast {
    border-radius: 0 0 20px 20px;
    box-shadow: 0 14px 24px rgba(0,0,0,0.16);
    padding: 20px 40px;
  }

  .Toastify__toast--error {
    background-color: #A10202;
  }

  .Toastify__toast-body {
    font-size: 16px;
    letter-spacing: 1.14px;
    line-height: 19px;
  }

  .Toastify__close-button {
    width: 32px;
    height: 32px;
    background: url(${closeImage}) no-repeat;

    svg {
      display: none;
    }
  }
`;

export default Base;
