import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom';
import debugBg from '@assets/images/etiqueta_makeb_main.png';

import { DomaineCode, LabelXSmall, TitleLarge, LabelRegular, LabelXXSmall, LabelBig } from '@assets/styles/typography';
import { Green100, Green300, PageBg, Primary } from '@assets/styles/colors';

import step1 from '@assets/images/target_line_1.png';
import step2 from '@assets/images/target_line_2.png';
import step3 from '@assets/images/target_line_3.png';


export const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  min-height: 720px;
  padding: 48px;

  background: ${PageBg};
  color: ${Primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 1280px;

  @media print {
    margin-top: unset;
    width: unset;
    min-width: unset;
    height: unset;
    min-height: unset;
    // top: unset;
    flex-direction: column;
  }
`

export const LabelSection = styled.div`
  width: 50vw;
  max-width: 624px;
  height: 100vh;
  max-height: 720px;
  min-height: 720px;
  background: #F8F9F7;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 88px 162px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const Image = styled.img`
  filter: drop-shadow(0px 4px 20px rgba(58, 96, 6, 0.1));
`

export const ContentSection = styled.div`
  width: 50vw;
  max-width: 656px;
  max-height: 720px;
  padding: 90px 136px;

  @media print {
    width: 100vw;
    height: 100vh;
    max-width: unset;
    max-height: unset;
    padding: unset;
  }

  @media screen and (min-height: 600px) and (max-height: 719px) {
    padding-top: 60px;
    padding-bottom: 20px;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Logo = styled.div`
  width: 113px;
`

export const Logout = styled.div`
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Green300};
  margin-right: -8px;

  span {
    ${LabelXXSmall}
    font-weight: 500;
    font-size: 12px;
    padding-right: 10px;
  }
`

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: ${({debug})=> debug ?"40px":"90px"};

  @media print {
    margin-top: unset;
    width: unset;
    height: unset;
    top: unset;
  }

  @media screen and (min-height: 600px) and (max-height: 719px) {
    margin-top: 120px;
  }

  &.step-1 {
    .block-1 {
      &:before {
        background: url(${step1});
        width: 495px;
        height: 64px;
        top: 45px;
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &.step-2 {
    .block-2 {
      &:before {
        background: url(${step2});
        width: 452px;
        height: 278px;
        bottom: calc(100% - 55px);
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &.step-3 {
    .block-3 {
      &:before {
        background: url(${step3});
        width: 360px;
        height: 96px;
        bottom: calc(100% - 55px);
        visibility: visible;
        opacity: 1;
      }
    }
  }
`

export const FormTitle = styled.div`
  ${TitleLarge}
  width: 100%;
  color: ${Green100};
  margin-bottom: 40px;
`

export const FormBlock = styled.div`
  width: 100%;
  position: relative;

  &:before {
    content: '';
    right: calc(100% - 5px);
    position: absolute;
    transition: all 0.8s;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
`

export const FormLabel = styled.div`
  ${LabelRegular}
  color: ${Green300};
  margin-bottom: 8px;
  position: relative;

  > span {
    position: absolute;
    right: 0;
    font-size: 12px;
    line-height: 14px;
    color: #A9B29C;
  }
`

export const FormActions = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;


  &.second-step {
    margin-top: 12px;
  }
`

export const GoBack = styled.div`
  ${LabelBig};
  color: ${Green300};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 13px;
  margin-left: -13px;

  a {
    color: ${Green300};
  }

  span {
    padding-left: 24px;
  }

  img {
    margin-bottom: -2px;
  }
`

export const NextStep = styled.div`
  transition: all 0.3s;

  button {
    padding: 13px 24px 13px 56px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;

    span {
      display: inline-flex;

      span {
        padding-right: 24px;
      }

      img {
        display: flex;
        margin-bottom: -2px;
        margin-left: 28 px;
      }

      &.loader {
        padding-right: 0;
        position: absolute;
        left: 20px;
      }
    }
  }
`

export const FormTextFilled = styled.div`
  ${TitleLarge}
  font-weight: 500;
  color: ${Green100};
  margin-bottom: 40px;
`

export const LabelSampleContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

export const LabelName = styled.div`
  ${DomaineCode};
  font-size: 26px;
  line-height: 26px;
  letter-spacing: 0.12em;
  color: #000000;
  position: absolute;
  overflow: visible;
  right: unset;
  width: 270px;
  height: 54px;
  left: 147px;
  bottom: 297px;
  display: flex;
  align-items: center;
  transform: rotate(-90deg);


  @media screen and (min-height: 600px) and (max-height: 719px) {
    top: 44%;
    left: 23%;
    right: unset;
    bottom: unset;
  }
`

export const LabelCode = styled.div`
  ${DomaineCode};
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.015em;;
  text-transform: uppercase;
  position: absolute;
  top: 160px;
  color: #000000;
  right: unset;
  left: 292px;
  width: 80px;
  height: 20px;

  @media screen and (min-height: 600px) and (max-height: 719px) {
    left: 46%;
    right: unset;
    bottom: unset;
    top: calc(50% - 200px);
  }
`

export const LabelLot = styled.div`
  ${DomaineCode};
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.12em;
  position: absolute;
  color: #000000;
  right: unset;
  left: 402px;
  bottom: 260px;
  width: 20px;
  height: 20px;

  span {
    display: inline-flex;
    white-space: nowrap;
    width: 100%;
    height: unset;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%) rotate(-90deg);
  }
`


export const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const SuccessMsg = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  span {
    ${TitleLarge};
    display: inline-flex;
    color: ${Green100};
  }

  svg {
    margin-bottom: 11px;
  }
`

export const SuccessActions = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`



export const PrintCopy = styled.div`
  button {
    background: #fff;
    border: 1px solid ${Green100};
    color: ${Green100};
    padding-left: 32px;
    padding-right: 32px;

    &:hover {
      color: #fff;
    }
  }
`

export const GoToStart = styled.div`
  button {
    padding-left: 32px;
    padding-right: 32px;
  }
`

export const PrintPage = createGlobalStyle`
  // .noprint {
  //     display: none;
  // }
  // .flex-wrapper {
  //     display: flex!important;
  // }

  // .content-section {
  //   width: 100vw;
  //   height: 100vh;
  //   max-width: unset;
  //   max-height: unset;
  //   padding: unset;
  // }

  @page {
    margin: 1.1mm;
    size: A4
  }

  * {
    -webkit-text-size-adjust: none;
  }

  .print {
    display: none;
  }

  .noprint {
    // background: #2B2B2B;
  }

  body {
    margin: 0;
    // width: 129mm;
    // height: 205mm;
    background: #eee;
    overflow: hidden;
    // border: solid 1px #ddd;
  }

  .flex-wrapper {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  @media print {
     .noprint {
        display: none;
     }
     .flex-wrapper {
        display: flex!important;
     }
  }

  .ticket {
    width: 210mm;
    height: 296mm;
    position: relative;
    z-index: 4;
    transform: rotate(-180deg);

    // border: 1px dashed red;

    // width: 38mm;
    // height: 69mm;
  }

  .ticket:before {
    display: none;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    // z-index: -1;
    z-index: 0;

    background-image: url('${debugBg}');

    background-size: cover;

    // width: 38mm;
    // height: 69mm;
  }

  .ticket: after {
    content: "V5";
    // display: flex;
    display: none;
    padding: 3%;
    justify-content: center;
    align-items: center;
    border: 5px dashed black;
    position: fixed;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 30pt;
  }
`;

export const PrintedLabelName = styled.div`
  ${DomaineCode};
  letter-spacing: 0;
  font-size: 44pt;
  line-height: 1;
  color: #000000;
  display: flex;
  align-items: center;
  text-align: left;
  position: absolute;
  width: 150mm;
  height: 40mm;
  left: -0.5%;
  top: 49%;
  right: unset;
  bottom: unset;
  transform: rotate(270deg) translate(15%, -30%);
`

export const PrintedCode = styled.div`
  ${DomaineCode};
  letter-spacing: 0;
  font-size: 32pt;
  position: absolute;
  left: 34%;
  top: 14%;
  transform: rotate(180deg);
  width: 54mm;
  height: 17.5mm;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  // border: 2px dashed red;

  span {
    transform: rotate(180deg);
    display: flex;
    position: absolute;
    right: 0;
  }
`

export const PrintedLot = styled.div`
  ${DomaineCode};
  letter-spacing: 0;
  font-size: 24pt;
  position: absolute;
  width: 150mm;
  height: 15mm;
  line-height: 1;
  text-align: center;
  display: flex;
  align-items: center;
  transform: rotate(-90deg);
  top: 40%;
  left: 29%;
  // border: 2px dashed red;
`
