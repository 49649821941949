import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Page from '@components/templates/Page';
import Header from '@components/molecules/Header';
import Button from '@components/molecules/Button';
import BottomContainer from '@components/molecules/BottomContainer';

import CreatorContext from '@context/creatorContext';
import { RESET_STATE } from '@context/consts';

import finishImage from '@assets/images/finish.svg'

import PropTypes from 'prop-types';
import {gotoFullscreen} from '@src/helpers/fullscreen'
import {
  PageWrapper,
  Title,
  TitleContainer,
} from './style';

const PostPrint = ({ className }) => {
  const { dispatch } = useContext(CreatorContext);

  const history = useHistory();

  const exit = () => {
    gotoFullscreen();
    dispatch({
      type: RESET_STATE
    });
    history.push('/');
  }

  return (
    <Page title="Configurar Impressão" description="PrePrint Page" className={className}>
      <PageWrapper>
        <Header />

        <TitleContainer>
          <img src={finishImage} alt="Impressão enviada com sucesso!" />
          <Title>Impressão enviada com sucesso!</Title>
        </TitleContainer>

        <BottomContainer>
          <BottomContainer.Item>
            <Button
              htmlType="button"
              type="secondary"
              reverseArrow
              onClick={() => {
                gotoFullscreen();
                history.push(`/configurar-impressao`);
              }}
            >
              CONFIGURAR NOVA IMPRESSÃO
            </Button>
          </BottomContainer.Item>
          <BottomContainer.Item>
            <Button
              htmlType="button"
              onClick={exit}
            >
              FINALIZAR
            </Button>
          </BottomContainer.Item>
        </BottomContainer>

      </PageWrapper>
    </Page>
  )
}

PostPrint.propTypes = {
  className: PropTypes.string,
};

PostPrint.defaultProps = {
  className: '',
};

export default PostPrint;
