import React, { useContext, useEffect, useState } from 'react';

import Page from '@components/templates/Page';

import logoImage from '@assets/images/logo.svg';

import Transition from '@components/molecules/Transition';
import CreatorContext from '@context/creatorContext';
import { RESET_STATE } from '@context/consts';
import AuthService from '@api/services/auth';

import TopBar from '@components/molecules/TopBar';

import { getTypeUser } from '@utils/auth';

import etiquetaMakeBStore from '@assets/images/etiqueta_makeb_main_store.png';
import etiquetaMakeB from '@assets/images/etiqueta_makeb_main.png';
import etiquetaQDB from '@assets/images/etiqueta_qdb_main.png';

import {
  PageWrapper,
  Logo,
  OptionsWrapper,
  OptionItem,
  OptionLabel,
  OptionDescription,
} from './style';

const Home = () => {
  const { dispatch } = useContext(CreatorContext);
  const [typeUser, setTypeUser] = useState(false);

  useEffect(() => {
    dispatch({ type: RESET_STATE });
  }, []);

  async function getUserType() {
    setTypeUser(getTypeUser());
  }

  useEffect(() => {
    getUserType();
  }, []);

  const EtiquetasElement = {
    loja: (
      <>
        <OptionItem to="/makebstore">
          <OptionLabel>MakeB. Color Lab</OptionLabel>
          <img src={etiquetaMakeBStore} width="164" alt="MakeB. Color Lab" />
        </OptionItem>
      </>
    ),
    default: (
      <>
        <OptionItem to="/makeb">
          <OptionLabel>MakeB. Color Lab</OptionLabel>
          <img src={etiquetaMakeB} width="164" alt="MakeB. Color Lab" />
        </OptionItem>
        <OptionItem to="/qdb">
          <OptionLabel>QDB Base Ideal</OptionLabel>
          <img src={etiquetaQDB} alt="QDB Base Ideal" />
        </OptionItem>
      </>
    ),
  };

  return (
    <Page title="Home" description="Welcome to Nodo Csr!" showNav={false}>
      <Transition type="fadein" trigger>
        <PageWrapper>
          <TopBar />

          <OptionsWrapper>
            {EtiquetasElement[typeUser] || EtiquetasElement.default}

            <div style={{ display: 'none' }}>
              <OptionItem to="/registro">
                <OptionLabel>Criar nova fragância</OptionLabel>
                <OptionDescription>Criar fragrância a partir de um agendamento</OptionDescription>
              </OptionItem>

              <OptionItem to="/busca">
                <OptionLabel>Buscar fragrância</OptionLabel>
                <OptionDescription>
                  Buscar por nome do cliente ou código do produto
                </OptionDescription>
              </OptionItem>

              <OptionItem to="/export">
                <OptionLabel>Relatório</OptionLabel>
                <OptionDescription>Buscar relatório entre duas datas</OptionDescription>
              </OptionItem>
            </div>
          </OptionsWrapper>
        </PageWrapper>
      </Transition>
    </Page>
  );
};

export default Home;
