import styled, { css } from 'styled-components';
import { Green200, Green300, White } from '@assets/styles/colors';
import { LabelRegular } from '@assets/styles/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  cursor: pointer;
`;

export const CheckboxWrapper = styled.div`
  background: ${White};
  border: 1px solid #E2E2E2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;

  user-select: none;
  width: 14px;
  height: 14px;
  margin-right: 8px;
  cursor: pointer;
  margin-bottom: 24px;

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${Green200};
      border-color: ${Green200};

      span {
        color: ${White} !important;
        font-size: 12px;
      }
    `};
`;

export const Label = styled.span`
  ${LabelRegular}
  color: ${Green300};
  line-height: 16px;
  display: block;
  user-select: none;
  margin-bottom: 6px;
`;
