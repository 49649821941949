import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

import { getInitialState, saveStateReducer } from '@context/utils';
import {
  SET_CREATOR,
  SET_CREATOR_FRAGRANCE,
  SET_CREATOR_DESCRIPTION,
  SET_CREATOR_CURRENT,
  RESET_STATE,
} from './consts';

const initialState = {
  name: null,
  email: null,
  id: null,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CREATOR:
      return {
        ...state,
        ...payload,
      };

    case SET_CREATOR_FRAGRANCE:
      return {
        ...state,
        fragrances: payload,
      };

    case SET_CREATOR_DESCRIPTION:
      return {
        ...state,
        description: payload,
      };

    case SET_CREATOR_CURRENT:
      return {
        ...state,
        currentCreating: payload,
      };

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};

const CreatorContext = createContext(initialState);

const CreatorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    saveStateReducer('creator', reducer),
    getInitialState('creator', initialState),
  );

  return <CreatorContext.Provider value={{ state, dispatch }}>{children}</CreatorContext.Provider>;
};

CreatorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { CreatorProvider };
export default CreatorContext;
