import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import MaterialSlider from '@material-ui/core/Slider';
import { LabelXSmall } from '@assets/styles/typography';
import { Gray500, Primary, Secondary, White } from '@assets/styles/colors';

export const Label = styled.span`
  ${LabelXSmall};
  color: ${Gray500};
  margin-bottom: 32px;
  text-align: center;
  display: block;
`;

export const ChordsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;

  pointer-events: ${({ active }) => active ? 'auto' : 'none' };
`;

export const StyledSlider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;

  [class*="PrivateValueLabel-label-"] {
    &:after {
      content: '%'
    }
  }
`;

export const DecrementButton = styled.button`
  min-width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid ${Secondary};
  background: transparent;
  margin-right: 24px;
  color: ${Secondary};
  font-size: 24px;
  box-shadow: 0px 14px 24px 5px rgba(0,0,0,0.36);
  cursor: default;

  opacity: ${({ active }) => active ? 1 : 0.5};
`;

export const IncrementButton = styled.button`
  min-width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 1px solid ${Secondary};
  background: transparent;
  margin-left: 24px;
  color: ${Secondary};
  font-size: 24px;
  box-shadow: 0px 14px 24px 5px rgba(0,0,0,0.36);
  cursor: default;

  opacity: ${({ active }) => active ? 1 : 0.5};
`;

export const ButtonContainer = styled.div`
  margin-bottom: 30px;
`;

export const ChordName = styled.span`
  display: block;
  ${LabelXSmall};
  color: ${Secondary};
  margin-bottom: 23px;
`;

export const ChordVolume = styled.div`
  display: block;
  ${LabelXSmall};
  color: ${Secondary};
  min-width: 110px;
  text-align: right;
`;

export const ChordVolumeItem = styled.span`
  display: block;
  color: ${({ grey }) => grey ? Gray500 : White};
  padding: 2px 0;
`;

export const Slider = withStyles({
  root: {
    color: Secondary,
    height: 5,
  },
  thumb: {
    height: 19,
    width: 19,
    backgroundColor: Primary,
    border: '3px solid currentColor',
    marginTop: -5,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 2px)',
    top: '-30px',
    '& *': {
      background: 'transparent',
      color: Gray500,
      fontSize: 24,
    },
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
    backgroundColor: '#000',
  },
})(MaterialSlider);
