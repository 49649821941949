import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom';
import debugBg from '@assets/images/etiqueta_qdb_main.png';

import { DomaineCode, LabelXSmall, TitleLarge, LabelRegular, LabelXXSmall, LabelBig } from '@assets/styles/typography';
import { Green100, Green300, PageBg, Primary } from '@assets/styles/colors';

import step1 from '@assets/images/target_line_1_qdb.png';
import step2 from '@assets/images/target_line_2_qdb.png';


export const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  min-height: 720px;
  padding: 48px;

  background: ${PageBg};
  color: ${Primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 1280px;

  @media print { 
    margin-top: unset;
    width: unset;
    min-width: unset;
    height: unset;
    min-height: unset;
    // top: unset;
    flex-direction: column;
  }
`

export const LabelSection = styled.div`
  width: 50vw;
  max-width: 624px;
  height: 100vh;
  max-height: 720px;
  min-height: 720px;
  background: #F8F9F7;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 88px 162px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const Image = styled.img`
  filter: drop-shadow(0px 4px 20px rgba(58, 96, 6, 0.1));
`

export const ContentSection = styled.div`
  width: 50vw;
  max-width: 656px;
  max-height: 720px;
  padding: 90px 136px;

  @media print { 
    width: 100vw;
    height: 100vh;
    max-width: unset;
    max-height: unset;
    padding: unset;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const Logo = styled.div`
  width: 113px;
`

export const Logout = styled.div`
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Green300};
  margin-right: -8px;

  span {
    ${LabelXXSmall}
    font-weight: 500;
    font-size: 12px;
    padding-right: 10px;
  }
`

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 90px;

  @media print { 
    margin-top: unset;
    width: unset;
    height: unset;
    top: unset;
  }

  &.step-1 {
    .block-1 {
      &:before {
        background: url(${step1});
        width: 454px;
        height: 8px;
        top: 45px;
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &.step-2 {
    .block-2 {
      &:before {
        background: url(${step2});
        width: 459px;
        height: 146px;
        top: 45px;
        bottom: unset;
        right: calc(100% - 5px);
        visibility: visible;
        opacity: 1;
      }
    }
  }
`

export const FormTitle = styled.div`
  ${TitleLarge}
  width: 100%;
  color: ${Green100};
  margin-bottom: 40px;
`

export const FormBlock = styled.div`
  width: 100%;
  position: relative;

  &:before {
    content: '';
    right: calc(100% - 5px);
    position: absolute;
    transition: all 0.8s;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
`

export const FormLabel = styled.div`
  ${LabelRegular}
  color: ${Green300};
  margin-bottom: 8px;
  position: relative;

  > span {
    position: absolute;
    right: 0;
    font-size: 12px;
    line-height: 14px;
    color: #A9B29C;
  }
`

export const FormActions = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.second-step {
    margin-top: 100px;
  }
`

export const GoBack = styled.div`
  ${LabelBig};
  color: ${Green300};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 13px;
  margin-left: -13px;

  a {
    color: ${Green300};
  }

  span {
    padding-left: 24px;
  }

  img {
    margin-bottom: -2px;
  }
`

export const NextStep = styled.div`
  transition: all 0.3s;

  button {
    padding: 13px 24px 13px 56px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;

    span {
      display: inline-flex;

      span {
        padding-right: 24px;
      }

      img {
        display: flex;
        margin-bottom: -2px;
        margin-left: 28 px;
      }

      &.loader {
        padding-right: 0;
        position: absolute;
        left: 20px;
      }
    }
  }
`

export const FormTextFilled = styled.div`
  ${TitleLarge}
  font-weight: 500;
  color: ${Green100};
  margin-bottom: 34px;
`

export const LabelSampleContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

export const LabelName = styled.div`
  font-family: 'MaisonNeue';
  font-weight: 800;
  font-size: 30px;
  line-height: 27px;
  color: #443F39;
  text-transform: uppercase;
  position: absolute;
  width: 0;
  overflow: visible;
  left: 212px;
  right: unset;
  width: 180px;
  text-align: left;
  height: 20px;
  bottom: 395px;
`

export const LabelLot = styled.div`
  font-family: 'MaisonNeueMedium';
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  text-transform: uppercase;
  position: absolute;
  color: #443F39;
  bottom: 130px;
  right: unset;
  left: 212px;
  width: 200px;
  height: unset;

  .number {
    margin-left: 3px;
  }
`


export const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const SuccessMsg = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  span {
    ${TitleLarge};
    display: inline-flex;
    color: ${Green100};
  }

  svg {
    margin-bottom: 11px;
  }
`

export const SuccessActions = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`



export const PrintCopy = styled.div`
  button {
    background: #fff;
    border: 1px solid ${Green100};
    color: ${Green100};
    padding-left: 32px;
    padding-right: 32px;

    &:hover {
      color: #fff;
    }
  }
`

export const GoToStart = styled.div`
  button {
    padding-left: 32px;
    padding-right: 32px;
  }
`

export const PrintPage = createGlobalStyle`
  // .noprint { 
  //     display: none; 
  // }
  // .flex-wrapper {
  //     display: flex!important;
  // }

  // .content-section {
  //   width: 100vw;
  //   height: 100vh;
  //   max-width: unset;
  //   max-height: unset;
  //   padding: unset;
  // }

  @page {
    margin: 0;
    size: A4
  }

  * {
    -webkit-text-size-adjust: none;
  }
  
  .print {
    display: none;
  }
  
  .noprint {
    // background: #2B2B2B;
  } 
  
  body {
    margin: 0;
    // width: 129mm;
    // height: 205mm;
    background: #eee;
    overflow: hidden;
    // border: solid 1px #ddd;
  }
  
  .flex-wrapper {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  @media print { 
     .noprint { 
        display: none; 
     }
     .flex-wrapper {
        display: flex!important;
     }
  }

  .ticket {
    width: 210mm;
    height: 296mm;
    position: relative;
    z-index: 4;
    transform: rotate(-180deg);
  }
  
  .ticket:before {
    display: none;
    content: "";
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%; 
    height: 100%;  
    opacity: .5; 
    // z-index: -1;
    z-index: 0;

    background-image: url('${debugBg}');

    background-size: cover;
    width: 38mm;
    height: 69mm;
  }

  .ticket: after {
    content: "V7";
    display: none;
    // display: flex;
    padding: 3%;
    justify-content: center;
    align-items: center;
    border: 1px dashed black;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30pt;
  }
`;

export const PrintedLabelName = styled.div`
  font-family: 'MaisonNeueMedium';
  // font-family: 'MaisonNeue';
  // font-weight: 800;
  font-weight: 500;
  letter-spacing: 0;
  font-size: 40pt;
  line-height: 100%;
  color: #000000;
  display: flex;
  position: absolute;
  height: 8mm;
  width: 50mm;
  left: 14%;
  top: 41%;
  text-align: left;
  text-transform: uppercase;
  justify-content: flex-start;
  align-items: flex-start;
`

export const PrintedLot = styled.div`
  font-family: 'MaisonNeueMedium';
  font-weight: 500;
  letter-spacing: 0;
  font-size: 24pt;
  position: absolute;
  line-height: 1;
  text-align: left;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-transform: uppercase;

  width: 27mm;
  height: 5mm;
  top: 88%;
  left: 14%;
`

