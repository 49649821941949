import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  Paragraph,
  ParagraphSmall,
  HeadingLarge,
  HeadingMedium,
  LabelXSmall,
} from '@assets/styles/typography';
import { Primary, Secondary, Gray500, PageBg, Green100 } from '@assets/styles/colors';

export const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 48px;

  background: ${PageBg};
  color: ${Primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img``;

export const Label = styled.span`
  ${Paragraph};
  color: ${Gray500};
  margin-bottom: 16px;
  text-align: center;
  display: block;
`;

export const OptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 100px;

  justify-content: center;
`;

export const OptionItem = styled(Link)`
  width: 50%;
  min-height: 405px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #f8f9f7;
  border-radius: 8px;
  box-sizing: border-box;
  border: 4px solid transparent;

  padding: 32px;
  outline: none;
  margin-left: 32px;
  transition: all 0.3s;

  &:first-child {
    margin-left: 0;
  }

  img {
    width: 164px;
  }

  &:hover {
    border: 4px solid rgba(58, 96, 6, 0.2);
  }
`;

export const OptionLabel = styled.span`
  ${LabelXSmall};
  font-weight: 500;
  color: ${Green100};
  margin-bottom: 16px;
`;

export const OptionDescription = styled.span`
  ${ParagraphSmall};
  color: ${Gray500};
`;
