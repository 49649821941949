import styled from 'styled-components';
import { Secondary } from '@assets/styles/colors';

export const List = styled.ul`
  color: ${Secondary};
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 100px;
  right: 100px;
  top: 160px;
  z-index: 3;
`;

export const Item = styled.li`
  position: relative;
  opacity: ${props => props.active ? '1' : '.6'};
  width: 100%;

  &.center {
    min-width: 344px;

    &:after,
    &:before {
      content: '';
      height: 1px;
      width: 72px;
      background: #6C6C6C;

      position: absolute;
      top: 8px;
      right: 0;
    }

    &:after {
      left: 0;
    }

    text-align: center;
  }

  &.last {
    text-align: right;
  }
`;

export const Title = styled.h2`
  font-family: 'MaisonNeue';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;

  svg {
    margin-top: 3px;
    margin-left: -20px;
    position: absolute;
  }
`;

export const SubTitle = styled.h3`
  font-family: 'MaisonNeue';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  position: absolute;
  white-space: nowrap;
  padding-top: 7px;
`;

export const Chords = styled.span`
  width: 19px;
  height: 19px;
  line-height: 19px;
  text-align: center;
  border-radius: 100%;
  display: inline-block;
  margin: 2px 10px;

  font-family: 'MaisonNeue';
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;

  background: #6C6C6C;
  color: #2B2B2B;
`;
