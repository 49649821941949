import styled from 'styled-components';
import { LabelXSmall, ParagraphSmall } from '@assets/styles/typography';
import { Primary, Secondary, Error as ErrorColor, White, Gray500, Green100, Green300, InputBorder, Green200 } from '@assets/styles/colors';

export const Viewport = styled.div`
  position: relative;
  margin-bottom: 24px;
  box-sizing: border-box;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const StyledInput = styled.input`
  ${LabelXSmall};
  width: 100%;
  color: ${Green100};
  background: ${White};
  border: 1px solid ${props => props.error ? ErrorColor : InputBorder};
  padding: 13px 16px 14px;
  box-sizing: border-box;
  outline: none;
  transition: .3s;
  border-radius: 8px;

  &:focus {
    border: 1px solid ${Green200};
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
  cursor: pointer;
  color: ${Green200};

  span {
    display: block;
  }
`;

export const Label = styled.span`
  ${ParagraphSmall}
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.015em;
  font-weight: 500;
  display: block;
  color: ${Green300};
  margin-bottom: 8px;
`;

export const Error = styled.span`
  ${ParagraphSmall}
  visibility: ${props => props.error ? 'visible' : 'hidden'};
  opacity: ${props => props.error ? '1' : '0'};
  position: absolute;
  bottom: ${props => props.error ? '-22px' : '-10px'};
  color: ${ErrorColor};
  display: block;
  transition: .3s cubic-bezier(.215,.61,.355,1);
`;
