import styled from 'styled-components';

import { HeadingSmall, ParagraphHeight } from '@assets/styles/typography';
import { Gray500, Primary, Secondary, PageBg } from '@assets/styles/colors';

export const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 130px 100px 0;
  background: ${PageBg};
  display: flex;
  flex-direction: column;
`;

export const BasesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 150px 0 65px 0;
  align-self: center;
`;

export const Label = styled.span`
  ${ParagraphHeight};
  color: ${Gray500};
  margin-top: 16px;
`;

export const Title = styled.h1`
  ${HeadingSmall};
  font-weight: 600;
  color: ${Secondary};
  text-align: center;
`;

export const BasesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const ButtonWrapper = styled.div`
  margin-top: ${({ loaded }) => loaded ? '90px' : '680px'};
`;
