export const fontFamily = `font-family: 'Work Sans', sans-serif`;
export const domaine = `font-family: 'DomaineSans'`;

export const Paragraph = `
  ${fontFamily};
  font-weight: 400;
  font-size: 24px;
  line-height: 18px;
  letter-spacing: 0.06em;
`;

export const ParagraphHeight = `
  ${fontFamily};
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 1.71px;
  line-height: 32px;
`;

export const ParagraphSmall = `
  ${fontFamily};
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.06em;
`;

export const LabelSmall = `
  ${fontFamily};
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1.14px;
  line-height: 19px;
`;

export const LabelMediumStrong = `
  ${fontFamily};
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
`;

export const LabelMedium = `
  ${fontFamily};
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
`;

export const LabelLarge = `
  ${fontFamily};
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  letter-spacing: 0.06em;
`;

export const LabelLargeSemi = `
  ${fontFamily};
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.06em;
`;

export const LabelXLarge = `
  ${fontFamily};
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.06em;
`;

export const HeadingSmall = `
  ${fontFamily};
  font-weight: 600;
  font-size: 40px;
  letter-spacing: 4.25px;
  line-height: 37px;
`;

export const HeadingMedium = `
  ${fontFamily};
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.06em;
`;

export const HeadingLarge = `
  ${fontFamily};
  font-weight: 500;
  font-size: 54px;
  line-height: 56px;
  letter-spacing: 0.06em;
`;




export const TitleLarge = `
  ${fontFamily};
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.015em;
`;

export const LabelBig = `
  ${fontFamily};
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
`;

export const LabelRegular = `
  ${fontFamily};
  font-weight: 500;
  font-size: 14px;
  line-height: 16.42px;
  letter-spacing: -0.015em;
`;

export const LabelXSmall = `
  ${fontFamily};
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.015em;
`;

export const LabelXXSmall = `
  ${fontFamily};
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.015em;
`

export const DomaineCode = `
  ${domaine};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 91.6%;
  letter-spacing: -0.005em;
  text-transform: uppercase;
`