import React from 'react';

const MaisonNeue = 'static/fonts/text/MaisonNeueExt-ExtraBold.otf';
const MaisonNeueMedium = 'static/fonts/text/MaisonNeue-Medium.otf';
const DomaineSans = 'static/fonts/text/DomaineSansDisplay-Regular.woff';
const DomaineSans2 = 'static/fonts/text/DomaineSansDisplay-Regular.woff2';

const Fonts = () => (
  <>
    <style jsx>
      {`
        @font-face {
          font-family: 'MaisonNeue';
          src: url(${MaisonNeue}) format('opentype');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'MaisonNeueMedium';
          src: url(${MaisonNeueMedium}) format('opentype');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: 'DomaineSans';
      	  src: url(${DomaineSans2}) format('woff2'), url(${DomaineSans}) format('woff');
          font-weight: normal;
          font-style: normal;
        }
      `}
    </style>
  </>
)

export default Fonts;
