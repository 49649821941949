import styled from 'styled-components';

import { Primary, Secondary, Green100, White } from '@assets/styles/colors';
import { LabelLarge, LabelXSmall } from '@assets/styles/typography';

const theme = {
}

export const StyledButton = styled.button`
  margin-top: 8px;
  background: rgba(58, 96, 6, 0.5);
  border-radius: 8px;
  border: 1px solid transparent;
  color: ${White};
  cursor: pointer;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  transition: .3s;
  opacity: ${props => props.disabled ? '.6' : '1'};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};

  &:active, :hover {
    transition: none;
    background: ${Green100};
  }

  .loader {
    opacity: ${props => props.loading ? '1' : '0'};
    visibility: ${props => props.loading ? 'visible' : 'hidden'};
    margin-right: ${props => props.loading ? '10px' : '-16px'};
    display: inline-block;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
  }

  span {
    ${LabelXSmall};
  }

`;

export default StyledButton;
