import styled from 'styled-components';

import { Paragraph, HeadingLarge } from '@assets/styles/typography';
import { Primary, White, Secondary, Gray500, PageBg } from '@assets/styles/colors';

export const PageWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 48px;

  background: ${PageBg};
  color: ${Primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  width: 227px;
  margin-bottom: 94px;
`;

export const TitleContainer = styled.div`
`;

export const Label = styled.span`
`;

export const Title = styled.h1`
`;

export const Form = styled.form`
  width: 384px;

  .signing-input {
    margin-bottom: 32px;
  }
`;

export const ButtonWrapper = styled.div`
`;

export default PageWrapper;
