import cookie from 'js-cookie';
import { toast } from 'react-toastify';
import sha256 from 'crypto-js/sha256';
import AuthService from '@api/services/auth';
import { getErrorMessageByRequest } from '@modules/errors';

const typesLogin = {
  admin: {
    tokenName: `${process.env.REACT_APP_PROJECT_NAME_ADMIN}-token`,
    frontToken: process.env.REACT_APP_FRONT_TOKEN_ADMIN,
  },
  loja: {
    tokenName: `${process.env.REACT_APP_PROJECT_NAME_STORE}-token`,
    frontToken: process.env.REACT_APP_FRONT_TOKEN_STORE,
  },
};

export const getTypeUser = () => {
  let loginType = false;
  const values = Object.values(typesLogin);
  values.forEach((type, index) => {
    if (cookie.get(type.tokenName)) loginType = Object.keys(typesLogin)[index];
  });
  return loginType;
};

export const isAuthenticated = () => {
  return !!getTypeUser();
};

export const signin = async ({ username, password }) => {
  const hashDigest = sha256(password).toString();
  const loginType = typesLogin[username];
  if (loginType && hashDigest === loginType.frontToken) {
    cookie.set(loginType.tokenName, hashDigest, { expires: 1 });
  } else {
    toast.error('Usuário ou senha inválidos.');
  }

  // try {
  //   const { data } = await AuthService.signin({
  //     username,
  //     password,
  //   });

  //   cookie.set(tokenName, data.accessToken, { expires: 1 });
  // } catch(err) {
  //   const message = getErrorMessageByRequest(err);
  //   toast.error(message);
  // }
};

export const logout = () => {
  const values = Object.values(typesLogin);
  values.forEach((type) => {
    cookie.remove(type.tokenName);
  });
  window.location.reload();
};
